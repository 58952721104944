import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png'
import bg from '../../images/blog/roompage.jpg'
import Newslatter from '../../components/Newslatter/Newslatter';
import BlogList from '../../components/BlogList'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import BlogSection from '../../components/BlogSection';
import { Helmet } from 'react-helmet-async';

const BlogPageFullwidth =() => {
    return(
        <Fragment>
              <Helmet>
        <title>Blogs | Chira Meadows</title>
        <meta name="description" content="Dive into our blog for insights on the best things to do in Dapoli. Explore top tourist places, a comprehensive guide to choosing the perfect resort, and more. Stay informed and make the most of your visit to this beautiful coastal destination." />
        <meta name="keywords" content="Dapoli travel guide, things to do in Dapoli, tourist places in Dapoli, Chira Meadows blogs, travel tips, eco-resort, Dapoli attractions" />
        <meta property="og:title" content="Blogs | Chira Meadows" />
        <meta property="og:description" content="Dive into our blog for insights on the best things to do in Dapoli. Explore top tourist places, a comprehensive guide to choosing the perfect resort, and more. Stay informed and make the most of your visit to this beautiful coastal destination." />
        <meta property="og:type" content="website" />
    </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            
            <BlogSection/> 
            {/* <BlogList blLeft={'d-none'} blRight={'col-lg-10 offset-lg-1'}/> */}
            {/* <Newslatter nClass={'section-bg'}/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default BlogPageFullwidth;

