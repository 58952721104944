import React from 'react';

const Marquee = () => {
    return (
        <>
            <style>
                {`
                                    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

                    @keyframes marquee {
                        0% {
                            transform: translateX(100%);
                        }
                        100% {
                            transform: translateX(-100%);
                        }
                    }
                    .marquee-text {
                        white-space: nowrap;
                        color:grey;
                        font-size: 1.5rem;
                        font-weight: 300;  /* Medium font weight */
                                                font-family: 'Roboto', sans-serif;  /* Apply Roboto font */

                        animation: marquee 10s linear infinite;
                        color: #b32432;
                    }
                    .marquee-container {
                        background-color: white;
                        overflow: hidden;
                        width: 100%;
                        height: 30px;  /* Adjust the height as needed */
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                `}
            </style>
            {/* <div className="marquee-container">
                <span className="marquee-text">explore chira meadows ecoresort</span>
            </div> */}
        </>
    );
};

export default Marquee;
