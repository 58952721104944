import React, {Fragment, useEffect} from 'react';
import About2 from '../../components/about2/about2';
import BlogSection from '../../components/BlogSection';
import Destination from '../../components/Destination';
import Footer from '../../components/footer';
import FunFact from '../../components/FunFact';
import Hero2 from '../../components/hero2/hero2';
import Navbar from '../../components/Navbar'
import Newslatter from '../../components/Newslatter/Newslatter';
import PricingSection from '../../components/PricingSection';
import Rooms from '../../components/Rooms/Rooms';
import Scrollbar from '../../components/scrollbar'
import SearchSection from '../../components/SearchSection';
import ServiceSection2 from '../../components/ServiceSection2/ServiceSection2';
import Testimonial from '../../components/Testimonial';
import Logo from '../../images/logotest.png';
import ServiceSection from '../../components/ServiceSection';
import Hero from '../../components/hero';
import { useState } from "react";
import { Modal, Box, Typography, TextField, Button, IconButton } from "@mui/material";


// import Pop from '../../components/POP/Pop';
import { Helmet } from 'react-helmet-async';


const HomePage2 =() => {
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
      name: "",
      phone: "",
      email: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Submitted", formData);
        setIsOpen(false);
    };

    useEffect(()=>{
        setIsOpen(true);
    },[]);

    return(
        <Fragment>
          <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 340,
                bgcolor: "background.paper",
                boxShadow: 24,
                border:"none",
                p: 4,
                borderRadius: 2,
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <img src={Logo} alt="Logo" style={{ width: 50, height: 50 }} />
                <IconButton onClick={() => setIsOpen(false)}>
                  x
                </IconButton>
              </Box>
              <Typography variant="h6" component="h2" textAlign="center" gutterBottom>
                Contact Us
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <TextField label="Name" name="name" value={formData.name} onChange={handleChange} required size="small" sx={{ borderRadius: 2 }} />
                <TextField label="Phone" name="phone" value={formData.phone} onChange={handleChange} required size="small" sx={{ borderRadius: 2 }} />
                <TextField label="Email" name="email" value={formData.email} onChange={handleChange} required size="small" sx={{ borderRadius: 2 }} />
                <TextField label="Location" name="location" value={formData.location} onChange={handleChange} required size="small" sx={{ borderRadius: 2 }} />
                <Button 
                  type="submit" 
                  variant="contained" 
                  sx={{ mt: 2, backgroundColor: "#303443", "&:hover": { backgroundColor: "#23272E" } }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Modal>
            <Helmet>
               <title>Chira Meadows</title>
                <meta name="description" content="Discover the serene beauty of Chira Meadows, an eco-friendly resort nestled in the lush hills of Dapoli. Enjoy our spacious luxury accommodations, gourmet dining, and a range of services designed for relaxation and adventure. Experience nature at its finest with breathtaking views, warm hospitality, and unforgettable memories." />
                <meta name="keywords" content="Chira Meadows, eco-resort in Dapoli, luxury accommodations, nature retreat, services, beautiful landscapes, relaxation, adventure activities" />
                <meta property="og:title" content="Chira Meadows" />
                <meta property="og:description" content="Discover the serene beauty of Chira Meadows, an eco-friendly resort nestled in the lush hills of Dapoli. Enjoy our spacious luxury accommodations, gourmet dining, and a range of services designed for relaxation and adventure. Experience nature at its finest with breathtaking views, warm hospitality, and unforgettable memories." />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* <Pop/> */}
            <Navbar topbarBlock={'wpo-header-style-2'} Logo={Logo}/> 
            <Hero/> 
            <SearchSection/> 
            <About2/> 
            <FunFact fClass={'section-padding'}/> 
            <Rooms/> 
            <ServiceSection2/> 
            {/* <PricingSection/>  */}
            <ServiceSection svClass={'section-padding'}/> 
            <Testimonial/> 
            <Destination/> 
            <BlogSection/> 
            {/* <Newslatter/>  */}
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};

export default HomePage2;