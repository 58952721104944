// import React, { useState } from 'react'
// import SimpleReactValidator from 'simple-react-validator';
// import axios from "axios";
// import {toast} from "react-toastify";

// const ContactForm = () => {

//     const [loading, setLoading] = useState(false);
//     const [forms, setForms] = useState({
//         name: '',
//         email: '',
//         subject: '',
//         phone: '',
//         message: ''
//     });
//     const [validator] = useState(new SimpleReactValidator({
//         className: 'errorMessage'
//     }));
//     const changeHandler = e => {
//         setForms({ ...forms, [e.target.name]: e.target.value })
//         if (validator.allValid()) {
//             validator.hideMessages();
//         } else {
//             validator.showMessages();
//         }
//     };

//     const submitHandler =  async (e)  => {
//         e.preventDefault();
//         console.log(e)
//         const name = forms.name;
//         const email = forms.email;
//         const subject = forms.subject;
//         const phone=forms.phone;
//         const message=forms.message;
//         if (validator.allValid()) {
//             try {
//                 setLoading(true);
//                 const res = await axios.post(
//                         `${process.env.REACT_APP_BASE_URL}/api/contact`,
//                         {
//                             name,
//                             email,
//                             phone,
//                             subject,
//                             message
//                         }
//                     );
//                     if (res.data.success) {
//                         // if(res.data.result==="Email already registered")
//                         // {
//                         //     toast('Email already registered');
//                         // }
//                         // else{
//                             toast.success('Subscribed Successfully!');
//                         // }
//                     }else {
//                       console.log("Error Occurred");
//                       toast.error(res.data.message);
//                     }
//                 } catch (error) {
//                     console.log(error);
//                     toast.error(
//                         "Error occurred while signing in. Please try again."
//                     );
//                 } finally {
//                     setLoading(false);
//                     validator.hideMessages();
//                     setForms({
//                         name: '',
//                         email: '',
//                         subject: '',
//                         phone: '',
//                         message: ''
//                     })
//                     // push('/login');
//                 }
//         } else {
//             validator.showMessages();
//         }
//     };

//     return (
//         <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
//             <div className="row">
//                 <div className="col col-lg-6 col-12">
//                     <div className="form-field">
//                         <input
//                             value={forms.name}
//                             type="text"
//                             name="name"
//                             onBlur={(e) => changeHandler(e)}
//                             onChange={(e) => changeHandler(e)}
//                             placeholder="Enter Your Name" />
//                         {validator.message('name', forms.name, 'required|alpha_space')}
//                     </div>
//                 </div>
//                 <div className="col col-lg-6 col-12">
//                     <div className="form-field">
//                         <input
//                             value={forms.email}
//                             type="email"
//                             name="email"
//                             onBlur={(e) => changeHandler(e)}
//                             onChange={(e) => changeHandler(e)}
//                             placeholder="Your Email" />
//                         {validator.message('email', forms.email, 'required|email')}
//                     </div>
//                 </div>
//                 <div className="col col-lg-6 col-12">
//                     <div className="form-field">
//                         <input
//                             value={forms.phone}
//                             type="phone"
//                             name="phone"
//                             onBlur={(e) => changeHandler(e)}
//                             onChange={(e) => changeHandler(e)}
//                             placeholder="Your phone" />
//                         {validator.message('phone', forms.phone, 'required|phone')}
//                     </div>
//                 </div>
//                 <div className="col col-lg-6 col-12">
//                     <div className="form-field" rowSpan="2">
//                     <textarea
//                         style={{ height: '100px' }}
//                         // rows="2" 
//                         onBlur={(e) => changeHandler(e)}
//                         onChange={(e) => changeHandler(e)}
//                         value={forms.subject}
//                         type="text"
//                         name="subject"
//                         placeholder="Subject"
//                         >
//                     </textarea>
//                     {validator.message('subject', forms.subject, 'required')}
//                     </div>
//                 </div>
//                 {/* <div className="col col-lg-6 col-12">
//                     <div className="form-field">
//                         <select
//                         select there is onBlur className="col col-lg-6"
//                             onBlur={(e) => changeHandler(e)}
//                             onChange={(e) => changeHandler(e)}
//                             value={forms.subject}
//                             type="text"
//                             name="subject">
//                             <option>Choose a Service</option>
//                             <option>Room Service</option>
//                             <option>Laxary Resort</option>
//                             <option>Help Center</option>
//                         </select>
//                         {validator.message('subject', forms.subject, 'required')}
//                     </div>
//                 </div> */}
//                 <div className="col col-lg-12 col-12">
//                     <textarea
//                         onBlur={(e) => changeHandler(e)}
//                         onChange={(e) => changeHandler(e)}
//                         value={forms.message}
//                         type="text"
//                         name="message"
//                         placeholder="Message">
//                     </textarea>
//                     {validator.message('message', forms.message, 'required')}
//                 </div>
//             </div>
//             <div className="submit-area">
//                 <button type="submit" className="theme-btn">Submit Now</button>
//             </div>
//         </form >
//     )
// }

// export default ContactForm;
import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

const ContactForm = () => {
    const [loading, setLoading] = useState(false);
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const changeHandler = (e) => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();

        // Prepare the form data from user input
        const formData = {
            name: forms.name,
            email: forms.email,  // User's email
            phone: forms.phone,
            subject: forms.subject,
            message: forms.message,
            
        };

        // Validate form
        if (validator.allValid()) {
            try {
                setLoading(true);

                // Send email via EmailJS (using your service, template, and user ID)
                emailjs.send(
                    'your_service_id',          // Replace with your EmailJS service ID
                    'your_template_id',         // Replace with your EmailJS template ID
                    formData,                   // User form data
                    'your_user_id'              // Replace with your EmailJS user ID
                )
                    .then((response) => {
                        console.log('Message sent successfully:', response);
                        toast.success('Message sent successfully!');
                    })
                    .catch((err) => {
                        console.error('Failed to send message:', err);
                        toast.error('Failed to send message. Please try again!');
                    })
                    .finally(() => {
                        setLoading(false);
                        validator.hideMessages();

                        // Reset form fields
                        setForms({
                            name: '',
                            email: '',
                            phone: '',
                            subject: '',
                            message: ''
                        });
                    });
            } catch (error) {
                console.log(error);
                toast.error('Error occurred. Please try again.');
                setLoading(false);
            }
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={submitHandler} className="contact-validation-active">
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Enter Your Name" />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Your Email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Your Phone" />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <textarea
                            style={{ height: '100px' }}
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            value={forms.subject}
                            type="text"
                            name="subject"
                            placeholder="Subject" />
                        {validator.message('subject', forms.subject, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <textarea
                        onBlur={changeHandler}
                        onChange={changeHandler}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Message" />
                    {validator.message('message', forms.message, 'required')}
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn" disabled={loading}>
                    {loading ? 'Sending...' : 'Submit Now'}
                </button>
            </div>
        </form>
    );
};

export default ContactForm;
