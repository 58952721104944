import { display, maxWidth } from '@mui/system';
import React, { useState, useEffect } from 'react';

const Carousel = () => {
    const iframes = [
        "https://www.youtube.com/embed/GgSPsS31K4s?si=L9KryDW5fTEMRwNZ",
        "https://www.youtube.com/embed/TgRrDIui13Y?si=JhySwcTl94Zv34CE",
        "https://www.youtube.com/embed/5Qshf1yNt-w?si=DbIYyBVBnltGIVel",
        "https://www.youtube.com/embed/Ou_rX7c6nxY?si=rkOZWctckKLzS7lP",
        "https://www.youtube.com/embed/4QDQHsMq9Bg?si=6JDam38Gu-pvsPUw",
        "https://www.youtube.com/embed/lT_uZ8N00GQ?si=E37bxUuIlakqCeXC",
        "https://www.youtube.com/embed/oVCF7YLT1co?si=ImtcYc1IqRedbD8W",
        "https://www.youtube.com/embed/INx6co5temk?si=2RMRbEQB_ko6PIuv",
        "https://www.youtube.com/embed/KOtlAKQvldo?si=-EzdasciZJKggrI_",
        "https://www.youtube.com/embed/fMCWV7_V0kE?si=EIj2unsVSZJIJkGA",
    ];

    const [index, setIndex] = useState(0);

    const nextSlide = () => {
        setIndex((prevIndex) => (prevIndex + 1) % (iframes.length-4));
    };

    const prevSlide = () => {
        setIndex((prevIndex) => (prevIndex - 1 + iframes.length) % (iframes.length-4));
    };

    useEffect(() => {
        // const interval = setInterval(nextSlide, 5000);
        // return () => clearInterval(interval);
    }, [iframes.length]);

    return (
        <div className="carousel-container" style={{...styles.carouselContainer, display:"flex", justifyContent:"center", alignItems:"center"}}>
            
            <iframe 
                width="580" 
                height="300" 
                src={iframes[index]} 
                title="YouTube video player" 
                frameBorder="0"  
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen>
            </iframe>

            <button onClick={prevSlide} style={styles.prev}>&#10094;</button>
            <button onClick={nextSlide} style={styles.next}>&#10095;</button>
        </div>
    );
};

const styles = {
    carouselContainer: {
        position: 'relative',
        width: '80%',
        maxWidth:"600px",
        margin: 'auto',
        height: "320px",
        overflow: 'hidden',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        backgroundColor: "white",
        textAlign: "center",
    },
    prev: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        border: 'none',
        padding: '10px',
        cursor: 'pointer',
        fontSize: '18px',
        left: '10px'
    },
    next: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        border: 'none',
        padding: '10px',
        cursor: 'pointer',
        fontSize: '18px',
        right: '10px'
    },
    iframe:{
      position: 'absolute',
      top: '50%',
      transition: 'transform 0.5s ease-in-out',
    translate:'translateX(-100%)'
    }
};

export default Carousel;
