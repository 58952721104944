// import React from 'react'
// import { Link } from 'react-router-dom'
// import blogs from '../../api/blogs'
// import SectionTitleS2 from '../SectionTitleS2'

// const BlogSection = (props) => {

//     const ClickHandler = () => {
//         window.scrollTo(10, 0);
//     }

//     return (
//         <section className={`wpo-blog-section section-padding ${props.blogClass}`} style={{paddingTop:'60px'}}>
//             <div className="container">
//                 <SectionTitleS2 topTitle={'News'} MainTitle={'The Best Gateway'} subTitle={''} />
//                 <div className="wpo-blog-items">
//                     <div className="row">
//                         {blogs.map((blog, Bitem) => (
//                             <div className="col col-lg-4 col-md-6 col-12" key={Bitem}>
//                                 <div className="wpo-blog-item">
//                                     <div className="wpo-blog-img">
//                                         <img src={blog.screens} alt="" />
//                                     </div>
//                                     <div className="wpo-blog-content">
//                                         <div className="wpo-blog-content-top">
//                                             <div className="b-top">
//                                                 <div className="b-top-inner">
//                                                     <h2><Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>{blog.title}</Link></h2>
//                                                     <p>{blog.description}</p>
//                                                 </div>
//                                             </div>
//                                             <Link className="b-btn" onClick={ClickHandler} to={`/blog-single/${blog.id}`}>Read More...</Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// }

// export default BlogSection;
import React from 'react';
import { Link } from 'react-router-dom';
import blogs from '../../api/blogs';
import SectionTitleS2 from '../SectionTitleS2';

const BlogSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const cardStyle = {
        // Removed the outer border and ensured no outer white space
        padding: '0',  // Removed padding around the card
        margin: '0',   // Removed margin to avoid outer white space
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',  // Ensure content is stacked vertically
    };

    const blogImgStyle = (backgroundImage) => ({
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '200px',
        borderRadius: '8px',
    });

    return (
        <section className={`wpo-blog-section section-padding ${props.blogClass}`} style={{ paddingTop: '60px' }}>
            <div className="container">
                <SectionTitleS2 topTitle={'News'} MainTitle={'Stories from the Resort'} subTitle={''} />
                <div className="wpo-blog-items">
                    <div className="row">
                        {blogs.map((blog, Bitem) => (
                            <div className="col-lg-3 col-md-4 col-12" key={Bitem} style={{ marginBottom: '30px' }}> {/* Add margin-bottom for space between rows */}
                                <div 
                                    className="wpo-blog-item" 
                                    style={cardStyle} 
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'translateY(-10px)'} // hover effect
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'translateY(0)'} // revert hover effect
                                >
                                    <div className="wpo-blog-img" style={blogImgStyle(blog.screens)}></div>
                                    <div className="wpo-blog-content" style={{ flexGrow: 1 }}> {/* Ensures content fills the remaining space */}
                                        <div className="wpo-blog-content-top" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <div className="b-top">
                                                <div className="b-top-inner">
                                                    {/* Italicized Title */}
                                                    <h2 style={{ fontStyle: 'italic' }}><Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>{blog.title}</Link></h2>
                                                    
                                                    {/* Italicized Text */}
                                                    <p className="truncated-description" style={{ fontStyle: 'italic' }}>{blog.description}</p>
                                                </div>
                                            </div>
                                            <Link className="b-btn" onClick={ClickHandler} to={`/blog-single/${blog.id}`}>Read More...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* CSS Styles for Truncating Text */}
            <style jsx>{`
                .truncated-description {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;  /* Limits text to 2 lines */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                }

                .wpo-blog-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    height: 100%;
                }

                .b-top-inner {
                    padding-top: 15px;  /* Added padding to give uniform spacing */
                }
            `}</style>
        </section>
    );
};

export default BlogSection;
