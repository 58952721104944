import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import bg from '../../images/room/roompage.jpg'
import Testimonial from '../../components/Testimonial';
import Newslatter from '../../components/Newslatter/Newslatter';
import './ne.css'
import Slider from "react-slick";
import Carousel from "./carousel";

const RoomPage =() => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };
    const imageUrls = [
        "https://www.youtube.com/watch?v=oVCF7YLT1co",
        "https://www.youtube.com/watch?v=INx6co5temk",
        "https://www.youtube.com/watch?v=4-LyXZXy-AQ",
        "https://www.youtube.com/watch?v=Ou_rX7c6nxY",
        "https://www.youtube.com/watch?v=3Zzn4sN5mBI",
        "https://www.youtube.com/watch?v=KOtlAKQvldo",
        "https://www.youtube.com/watch?v=lT_uZ8N00GQ",
        "https://www.youtube.com/watch?v=4QDQHsMq9Bg",
        "https://www.youtube.com/watch?v=fMCWV7_V0kE",
        "https://www.youtube.com/shorts/1wrhQSqOwyQ",
        "https://www.youtube.com/shorts/IsQJV9Hso6U",

      ];

    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Review'} pagesub={'Review'} bg={bg}/> 
            <div style={{marginTop:'5rem'}}></div>
            <Testimonial /> 
            {/* <Newslatter/> */}
            <div className="mx-auto p-4">
                <Carousel items={imageUrls} />
            </div>
             {/* <Slider {...settings}>
      {[...Array(3)].map((_, i) => (
        <div key={i} className='fhfh' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <iframe width="560" height="250" src='https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W' title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          <iframe width="560" height="250" src="https://www.youtube.com/embed/vGhR-BsN07s?si=prd9LSSHSywaziLK" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
      ))}
    </Slider> */}
            {/* <div className='fhfh' style={{display:'flex',justifyContent:'space-evenly'}}>
                <iframe width="560" height="250" src='https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="250" src="https://www.youtube.com/embed/vGhR-BsN07s?si=prd9LSSHSywaziLK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="250" src='https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="250" src="https://www.youtube.com/embed/vGhR-BsN07s?si=prd9LSSHSywaziLK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="250" src='https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="250" src="https://www.youtube.com/embed/vGhR-BsN07s?si=prd9LSSHSywaziLK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div> */}
            {/* <div className='fhfh' style={{display:'flex',justifyContent:'space-evenly'}}>
                <iframe width="560" height="315" src='https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="315" src='https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="315" src='https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/vGhR-BsN07s?si=prd9LSSHSywaziLK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default RoomPage;
