import simg1 from '../images/service/img-1.jpg'
import simg2 from '../images/service/img-2.jpg'
import simg3 from '../images/service/img-3.jpg'
import simg4 from '../images/service/img-4.jpg'
import simg5 from '../images/service/img-5.jpg'
import simg6 from '../images/service/img-6.jpg'
import simg7 from '../images/service/img-7.jpg'

import singleImg1 from '../images/service-single/Coastal_Aroma_5.JPG'
import singleImg2 from '../images/service-single/game.jpg'
import singleImg3 from '../images/service-single/convergence.jpg'
import singleImg4 from '../images/service-single/Spa 1.JPG'
import singleImg5 from '../images/service-single/Gym (1).JPG'
import singleImg6 from '../images/service-single/Reception.JPG'
import singleImg7 from '../images/service-single/breakfast.JPG'
import singleImg8 from '../images/service-single/PPD_8744.JPG'
import singleImg9 from '../images/service-single/swimmingpool.png'
import singleImg10 from '../images/service-single/jac.jpg'






const Services = [
    {
        id: '1',
        fIcon:'flaticon-food-tray',
        title:'Coastal Aroma Restaurant',
        subtitle:'A DELIGHTFUL BLEND OF FLAVORS FROM THE COAST, SERVED TO YOU WITH LOVE',
        // title:'Delicious Food',
        description:'Coastal aroma, a fine dine coastal cuisine restaurant . A culinary delight',
        titledesc:'A MULTI-CUISINE RESTAURANT',
        titledesc1:'EXPERIENCE KONKAN’S VEGETARIAN DELIGHTS IN DAPOLI.',
        titledesc2:'A CULINARY JOURNEY TO THE COAST, RIGHT AT YOUR TABLE.',
        titledesc3:'DISCOVER THE TRUE FLAVORS OF THE KONKAN COAST IN EVERY BITE AT DAPOLI.',
        desc:'A multi-cuisine restaurant that specializes in offering a delectable range of coastal cuisine. With a focus on delivering the authentic taste and flavors of the coast, the restaurant promises a unique culinary experience. Whether you’re a seafood lover or simply a food enthusiast, Coastal Aroma’s diverse menu, warm ambiance, and excellent service will surely make your dining experience memorable.',
        desc1:'We offer a range of vegetarian specialties that are unique to the region. Some of the popular vegetarian dishes of Konkan cuisine that you can find in Dapoli include Sol Kadhi, Kaju Kothimbir Vadi, Alu Wadi, Kairichi Dal, Amboli, Bharli Vangi, Kala Vatana Usal, Dalimbi Usal, and Modak.',
        desc3:'Dapoli is considered one of the best places for coastal food due to its location along the Konkan coast in Maharashtra, India. The town is known for its abundant supply of fresh seafood, which is a staple ingredient in coastal cuisine. The warm and humid climate of Dapoli is also conducive to the growth of various herbs and spices that are used to prepare traditional coastal dishes. In addition, the local cuisine in Dapoli has been influenced by the diverse cultural and ethnic groups that have lived in the area over the years, leading to a unique and flavorful blend of culinary traditions. All of these factors combine to make Dapoli an ideal destination for those looking to experience the authentic taste of coastal cuisine.',
        simg1:simg1,
        ssImg:singleImg1,
        imgs:["Cluster Seating 1.JPG","Coastal Aroma.JPG","Coastal Aroma 7.JPG"],
    },
    {
        id: '2',
        fIcon:'fi-rs-puzzle-piece',
        padding: true,
        // fIcon:'flaticon-parking',
        title:'Game Zone',
        description:' Immerse yourself in a world of entertainment and excitement with our diverse collection of thrilling and engaging games for all ages.',
        // title:'Parking Area',
        // description:'Convenient and secure on-site parking ensures hassle-free arrivals and departures for our guests.',
        desc:'',
        desc1:'',
        imgs:["game/1.jpg","game/2.jpg","game/3.jpg"],
        simg1:simg2,
        ssImg:singleImg2,
    },
    {
        id: '3',
        fIcon:'fi-rs-house-chimney',
        padding: true,
        title:'Convergence Hall',
        description:'Our state-of-the-art convergence hall is designed for successful meetings, conferences, and events, providing a versatile space equipped with modern amenities.',
        desc:'',
        desc1:'',
        imgs:["Cluster Seating 1.JPG","box_seating_1.JPG","Theatre Seating 2.JPG"],
        simg1:simg3,
        ssImg:singleImg3,
    },
    
    {
        id: '4',
        fIcon:'flaticon-mortar',
        title:'Spa On Demand',
        description:'Chira Meadows hosts an amazing place to relax; we call it paradise you may call it Ayurved Spa. We would love to introduce you to this amazing relaxing and refreshing experience.',
        simg1:simg4,
        desc:'',
        desc1:'',
        imgs:["Cluster Seating 1.JPG","Spa 3.JPG","Spa 1.JPG"],
        ssImg:singleImg4,
    },
    
    {
        id: '5',
        fIcon:'flaticon-dumbell',
        title:'Exercise Space',
        description:'Keep up with your fitness routine whilst you’re away with our free in-house gym.',
        desc:'',
        desc1:'',
        simg1:simg5,
        ssImg:singleImg5,
        imgs:["Cluster Seating 1.JPG","Gym 1.JPG","PPD_8726.JPG"],
    },
    {
        id: '6',
        fIcon:'flaticon-wifi',
        title:'Free Wifi',
        description:'Stay connected with complimentary high-speed WiFi, allowing you to seamlessly browse, work, or stream during your stay.',
        desc:'',
        desc1:'',
        simg1:simg6,
        ssImg:singleImg6,
    },
    {
        id: '7',
        fIcon:'flaticon-cappuccino',
        title:'Breakfast',
        description:'Start your day with a delicious complimentary breakfast, featuring a variety of options to suit every palate and ensure a delightful morning experience.',
        desc:'',
        desc1:'',
        simg1:simg7,
        ssImg:singleImg7,
    },
    {
        id: '8',
        fIcon:'flaticon-swimmer',
        title:'Swimming Pool',
        description:`Dive into relaxation at our resort's swimming pool, an oasis of crystal-clear water surrounded by lush greenery. Perfect for laps or leisurely swims, it's a tranquil retreat for all ages.`,
        desc:'',
        desc1:'',
        simg1:simg7,
        ssImg:singleImg9,
    },
    {
        id: '9',
        fIcon:'fi-rr-water',
        title:'Jacuzzi',
        padding: true,
        description:`Unwind in the warm embrace of our Jacuzzi, a luxurious haven designed to soothe your senses. With its bubbling waters and serene ambiance, it's the perfect spot to relax and rejuvenate.`,
        desc:'',
        desc1:'',
        simg1:simg7,
        ssImg:singleImg10,
    },
    {
        id: '10',
        fIcon:'flaticon-more',
        title:'Other Services',
        description:'Explore a range of additional services tailored to enhance your stay, from spa and fitness facilities to personalized concierge services, ensuring a well-rounded and memorable experience.',
        desc1:'At our reception, experience seamless check-ins and personalized service. Our attentive staff awaits to ensure a smooth arrival and departure, catering to your every need. From travel assistance to local insights, our reception is your first point of contact for a memorable stay, where hospitality meets efficiency.',
        desc:'Indulge in the inviting ambiance of our hotel lobby, where contemporary design meets warm hospitality. The lobby serves as a stylish gateway, offering a relaxed atmosphere for guests to unwind, socialize, or catch up on work. Immerse yourself in comfort and sophistication from the moment you step through our doors.',
        titledesc:'Lobby',
        titledesc1:'Reception',
        simg1:simg1,
        ssImg:singleImg8,
        imgs:["Cluster Seating 1.JPG","19a.jpg","20a.jpg"],
        imgs1:["Cluster Seating 1.JPG","Lobby.JPG","Lobby 3.JPG"],
    },
]
export default Services;